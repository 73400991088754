import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Home: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const goToPlayStore = () =>{

    window.open(
      'https://play.google.com/store/apps/details?id=com.roqnetworks.area',
      '_blank' 
    );

  }

 if(window.innerWidth > 650){
  return (

    <div>
    <div style={{margin:0, padding:0, flexDirection:'column', backgroundColor:'black', zIndex:99999, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} >

    <h1 style={{fontSize:56, fontFamily:'Montserrat', fontWeight:700, position:'absolute', top:140}}>Roq Networks</h1>

    <h5 style={{color:'white', marginTop:20,position:'absolute', top:200}}>Building the future.</h5>

    <img src={bg2} style={{height:'auto', width:'45vw', backgroundColor:"#000000", margin:0, marginTop:0, padding:0}}/>

    {/* <h5 style={{color:'white', marginTop:20}}>Download Here</h5>

    <img src={gplay} onClick={goToPlayStore} style={{ cursor:'pointer', height:'auto', width:'10vw', backgroundColor:"#000000", margin:0, marginTop:20, padding:0}}/>
     */}

     </div> 

      <div style={{backgroundColor:'white',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'black',marginTop:-20}}>Who We Are</h2>

          <p style={{color:'black', margin:10, fontSize:14, textAlign:'center'}}>Roq Networks exists to connect the world in newer ways, building new technologies, converting ideas to execution and enhancing daily lives of people.</p>
            
      </div>

      <div style={{backgroundColor:'black',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'white',marginTop:-20}}>Area</h2>

          <p>The local social network.</p>

          <a href="https://www.area-app.com" style={{color:'white', margin:10, fontSize:14, textAlign:'center'}}>www.area-app.com</a>
            
      </div>

      {/* <div style={{backgroundColor:'white',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'black',marginTop:-20}}>Privacy Policy</h2>

          <a href="/privacy" style={{color:'black', margin:10, fontSize:14, textAlign:'center'}}>Read our privacy policy here.</a>
            
      </div> */}

      <div style={{backgroundColor:'black',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2>Contact</h2>

          <p>Write to us at contact@roqnetworks.com</p>

            
      </div>

      </div>
    

);


 }
 else{

  return (

    <div>
    <div style={{margin:0, padding:0, flexDirection:'column', backgroundColor:'black', zIndex:99999, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} >

    <h1 style={{fontSize:43, fontWeight:700, position:'absolute', top:130}}>Roq Networks</h1>

    <h5 style={{color:'white', marginTop:25,position:'absolute', top:170}}>Building the future.</h5>

    <img src={bg2} style={{height:'auto', width:'90vw', backgroundColor:"#000000", margin:0, marginTop:-50, padding:0}}/>

     </div>
           <div style={{backgroundColor:'white',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'black',marginTop:-20}}>Who We Are</h2>

          <p style={{color:'black', margin:10, fontSize:14, textAlign:'center'}}>Roq Networks exists to connect the world in newer ways, building new technologies, converting ideas to execution and enhancing daily lives of people.</p>
            
      </div>

      <div style={{backgroundColor:'black',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'white',marginTop:-20}}>Area</h2>

          <p>The local social network.</p>

          <a href="https://www.area-app.com" style={{color:'white', margin:10, fontSize:14, textAlign:'center'}}>www.area-app.com</a>
            
      </div>

      {/* <div style={{backgroundColor:'white',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2 style={{color:'black',marginTop:-20}}>Privacy Policy</h2>

          <a href="/privacy" style={{color:'black', margin:10, fontSize:14, textAlign:'center'}}>Read our privacy policy here.</a>
            
      </div> */}

      <div style={{backgroundColor:'black',height:'50vh', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

          <h2>Contact</h2>

          <p>Write to us at contact@roqnetworks.com</p>

            
      </div>

     </div>
    

);
 }

  
};

export default Home;
