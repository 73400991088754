import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home  from './screens/Home'
import Privacy from "./screens/Privacy"
import About from "./screens/About";
import Register from "./screens/Register";
import "./App.css";
import Login from "./screens/Login";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import  Dashboard  from "./screens/Dashboard"
import { getAuth } from "firebase/auth";
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import TopicScreen from "./screens/TopicScreen";
import UploadScreen from "./screens/Upload";
import ProfileScreen from "./screens/Profile";
import PeopleScreen from "./screens/PeopleScreen";
import CommentScreen from "./screens/CommentScreen";


const firebaseConfig = {
  apiKey: "AIzaSyDni4TNudQCl1TQPSxR5OTIfQUhZgZjaIg",
  authDomain: "wide-office-375709.firebaseapp.com",
  databaseURL: "https://wide-office-375709-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "wide-office-375709",
  storageBucket: "wide-office-375709.appspot.com",
  messagingSenderId: "563525968263",
  appId: "1:563525968263:web:7636a297f64decb86f3322",
  measurementId: "G-ZEZ630HPTH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();  
const realtimeDB = getDatabase()
export {db,auth, realtimeDB}



const App: React.FC = () => {

  useEffect(() => {

  }, []);


// Export firestore database
// It will be imported into your react app whenever it is needed



  return (
    <div>

      <div >
        <Routes>

        {/* <Route path="/" element={<TopicScreen />} />
        <Route path="/topics" element={<TopicScreen />} />
        <Route path="/share" element={<UploadScreen />} /> */}
          <Route path="/" element={<Home />} />
          {/* <Route path="/upload" element={<UploadScreen />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/people" element={<PeopleScreen />} />
          <Route path="/discuss" element={<CommentScreen />} /> */}


        </Routes>
      </div>
    </div>
  );
};

export default App;

